var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}}),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('span',[_vm._v("Requests")]),_c('router-link',{staticClass:"link",attrs:{"to":("/customerView/" + _vm.customerId + "/requests/new")}},[_c('v-btn',{staticClass:"ml-5",attrs:{"small":""}},[_vm._v("New Request")])],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-tabs',{attrs:{"background-color":"transparent","centered":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(!_vm.isResident)?_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" Due This Week ("+_vm._s(_vm.dueCount)+") ")]):_vm._e(),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" All Open ("+_vm._s(_vm.openCount)+") ")]),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v(" In Process ("+_vm._s(_vm.inProcessCount)+") ")]),_c('v-tab',{attrs:{"href":"#tab-4"}},[_vm._v(" Closed ("+_vm._s(_vm.closedCount)+") ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((4),function(i){return _c('v-tab-item',{key:i,attrs:{"value":'tab-' + i}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-text-field',{attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","variant":"outlined","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"item-key":"id","loading":_vm.isLoading,"items-per-page":50,"footer-props":{
                      'items-per-page-options': [50, 100, 150, 200, -1]
                    },"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.requestTypeName",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"blue-color",attrs:{"to":("/customerView/" + (item.communityId) + "/requests/" + (item.id) + "/update")}},[_vm._v(" "+_vm._s(_vm.getServiceRequestTypeName(item.requestType))+" ")])]}},{key:"item.comments",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.comments)+" ")])]}},{key:"item.streetLocation",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.location)+" ")])]}},{key:"item.dateCreated",fn:function(ref){
                    var item = ref.item;
return [_c('span',{style:(new Date(item.targetCompletionDate) < new Date()
                            ? 'color: red; font-weight: bold;'
                            : '')},[_vm._v(_vm._s(_vm.formatDate(item.dateCreated))+" ")])]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [(
                          _vm.permissions &&
                            _vm.permissions.serviceRequest &&
                            _vm.permissions.serviceRequest.canDelete
                        )?_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();item.deleteDialog = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete Request ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete request: "),_c('p',[_vm._v(_vm._s(item.title))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteRequest(item)}}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }